<template>
  <div contenteditable class="board mx-auto m-8">
    <p>Click to edit me ⇦</p>
    <p>Today is ⛅<br />
      <u>Underline</u></p>
  </div>
</template>

<script>
export default {
  name: "InputFieldNr1"
}
</script>

<style scoped lang="scss">
.board {
  font-family: "Dosis";
  font-size: 2.2rem;
  letter-spacing: .15em;
  text-transform: uppercase;
  line-height: 1.5;
  text-shadow: 1px 1px #666, 3px 3px #000;

  width: 250px;
  height: 350px;
  padding: 20px;
  overflow: hidden;
  color: #fff;
  border: 10px solid #eee;

  background: linear-gradient( #0e0e0e 65%, #030303 66%) top .03em center / 100% .30em repeat-y;
  box-shadow:
      0px -1px 0px 2px #aaa,
      0 -7px 5px -5px #000,
      0 -15px 30px -15px #000,
      0 3px 4px -2px #000,
      -60px 30px 70px -70px #000,
      70px 30px 70px -70px #000,
      0px 200px 20px rgba(#fff, .05),
      inset 0 0 25px #000,
      inset 0 0 5px #000;

&:focus {
   outline: none;
 }



u {
  color: #fd0;
  text-underline-position: under;
}
}

body {
  background: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
</style>