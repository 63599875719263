<template>
  <h1 class="text-center">Input Fields</h1>
  <ul class="text-center">


    <li class="mt-20 text-3xl">NR. 1

      <input-field-nr1/>
    </li>


    <li class="mt-40 text-3xl">NR. 2

      <input-field-nr2/>
    </li>

    <li class="mt-40 text-3xl">NR. 3

      <input-field-nr3/>
    </li>



    <li class="mt-40 text-3xl">NR. 4

      <input-field-nr4/>
    </li>
  </ul>
</template>

<script>

import InputFieldNr1 from "@/components/partials/InputFields/InputFieldNr1";
import InputFieldNr2 from "@/components/partials/InputFields/InputFieldNr2";
import InputFieldNr3 from "@/components/partials/InputFields/InputFieldNr3";
import InputFieldNr4 from "@/components/partials/InputFields/InputFieldNr4";
export default {
  name: "InputFields",

  components: {InputFieldNr1,InputFieldNr2,InputFieldNr3,InputFieldNr4}
}
</script>

<style scoped>

</style>